import React, { useState } from 'react';
import { BLOCKBUTTON, BUTTON, INPUT } from '../../Components/Forms';
import AcceptTerms from './AcceptTerms';
import { REGISTER, VALIDATE_EMAIL, SEND_TOKEN } from '../../GraphQL/Mutations';
import { useMutation } from '@apollo/client';
import { Link } from 'react-router-dom';
import { Validate } from '../../Helpers/Validate';
import OtpInput from 'react-otp-input';
import Session from '../../Helpers/Session';
import { Loading } from '../../Components/Loading';
import { validateNigerianPhoneNumber } from '../../Helpers/General';
import isImageBlurry from '../../Helpers/isImageBlurry';


const Register = () => {
    const [email, setEmail] = React.useState('');
    const [fullname, setFullname] = React.useState('');
    const [phone, setPhone] = React.useState('');
    const [photo, setPhoto] = React.useState('');
    const [step, setStep] = React.useState(1);
    const [otp, setOtp] = React.useState('');
    const [flashlightOn, setFlashlightOn] = useState(false);
    const [facingMode, setFacingMode] = useState<'user' | 'environment'>('user');
    const [stream, setStream] = React.useState<MediaStream | null>(null);
    const videoRef = React.useRef<any>(null);
    const canvasRef = React.useRef<any>(null);
    const [captureVideo, setCaptureVideo] = useState<boolean>();


    const handleCode = (value: string) => {
        setOtp(value.replace(/\D/g, '0'));
    };
    React.useEffect(() => {
        if (captureVideo) return;
        if (stream) {
            stream.getTracks().forEach((track: any) => track.stop());
        }
    }, [stream, captureVideo])

    const toggleFlashlight = () => {
        if (stream) {
            const track = stream.getVideoTracks()[0];
            const capabilities = track.getCapabilities();
            if (capabilities) {
                // const settings = track.getSettings();
                // track.applyConstraints({
                //     advanced: [{ torch: !settings.torch }]
                // });
            }
        }

        setFlashlightOn(!flashlightOn);
    };

    const switchCamera = () => {
        setFacingMode(prevMode => (prevMode === 'user' ? 'environment' : 'user'));
    };
    const takePhoto = () => {
        pauseVideo();
        if (videoRef.current) {
            const canvas = document.createElement("canvas");
            canvas.width = videoRef.current.videoWidth;
            canvas.height = videoRef.current.videoHeight;
            const ctx = canvas.getContext("2d");
            ctx!.drawImage(videoRef.current, 0, 0, canvas.width, canvas.height);
            const dataUrl = canvas.toDataURL("image/png");
            setPhoto(dataUrl);
            setStep(3);
        }
    };


    React.useEffect(() => {
        if (step === 2 && videoRef.current) {
            const getUserMedia = navigator.mediaDevices.getUserMedia.bind(navigator.mediaDevices);

            if (getUserMedia) {
                getUserMedia({
                    video: {
                        facingMode,
                        aspectRatio: 1 / 1,
                        width: { min: 480, ideal: 720, max: 1080 },
                        height: { min: 480, ideal: 720, max: 1080 }
                    }
                })
                    .then((stream: any) => {
                        setStream(stream);
                        videoRef.current!.srcObject = stream;
                        setCaptureVideo(true);
                        // handleVideoOnPlay(); // Start analyzing video when the stream starts
                    })
                    .catch((err: any) => {
                        console.error("Error accessing camera: ", err);
                        alert(`Error accessing camera: ${err.message}`);
                    });
            }
        }
        return () => {
            if (stream) {
                stream.getTracks().forEach(track => track.stop());
            }
            setCaptureVideo(false); // Stop capturing when leaving step
        };
    }, [step, facingMode]);


    const pauseVideo = () => {
        if (videoRef.current) {
            videoRef.current.pause();
            const tracks: any = videoRef?.current?.srcObject?.getTracks();
            if (tracks) {
                tracks.forEach((track: any) => track.stop());
            }
            setCaptureVideo(false);
        }
    };


    const [register, { loading: registerLoading }] = useMutation(REGISTER, {
        variables: {
            fullname,
            email,
            phoneNumber: phone,
            photo: photo ? photo : null,
            category: 'Merchant'
        },
        onCompleted: (data) => {
            if (data.register) {
                Session.showAlert({ str: 'Your account has been created. Please check your email for your verification code.', type: 'success' });
                setStep(4);
            } else {
                Session.showAlert({ str: "Something went wrong.", type: 'error' });
                return;
            }

        },
        onError: (error) => {
            Session.showAlert({ str: error.message, type: 'error' });
            return;
        }
    });

    const [sendToken] = useMutation(SEND_TOKEN, {
        variables: {
            email
        },
        onCompleted: (data) => {
            if (data.sendLoginToken) {
                Session.showAlert({ str: 'OTP has been sent to your email address.', type: 'success' });
            } else {
                Session.showAlert({ str: 'Email address not found.', type: 'error' });
                return;
            }
        },
        onError: (error) => {
            Session.showAlert({ str: error.message, type: 'error' });
            return;
        }
    });

    const [validateEmail] = useMutation(VALIDATE_EMAIL, {
        variables: {
            email: email,
            token: otp
        },
        onCompleted: (data) => {
            Session.showAlert({ str: 'Your Email has been verified successfully.', type: 'success' });
            setStep(5);
        },
        onError: (error) => {
            Session.showAlert({ str: error.message, type: 'error' });
            return;
        }
    })

    const handleSubmit = async () => {
        if (fullname.length < 3 || !Validate.string(fullname)) {
            Session.showAlert({ str: 'Please enter your full name.', type: 'warning' });
            return;
        }
        if (email.length < 3 || !Validate.email(email)) {
            Session.showAlert({ str: 'Please enter your valid email.', type: 'warning' });
            return;
        }
        if (!validateNigerianPhoneNumber(phone)) {
            Session.showAlert({ str: 'Please enter your valid phone number.', type: 'warning' });
            return;
        }
        if (!photo) {
            Session.showAlert({ str: 'Please upload your profile picture.', type: 'warning' });
            return;
        }
        await register();
    }
    const handleOTPSubmit = async () => {
        if (email.length < 3 || !Validate.email(email)) {
            Session.showAlert({ str: 'Please enter your valid email.', type: 'warning' });
            return;
        }
        if (otp.length !== 4) {
            Session.showAlert({ str: 'Please enter the 4-digit OTP sent to your email.', type: 'warning' });
            return;
        }
        await validateEmail();
    }

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;

        // Only allow numeric input
        const isNumeric = /^[0-9]*$/.test(value);

        // Allow input only if it's exactly 11 digits or empty (for clearing the field)
        if (isNumeric && (value.length <= 11)) {
            setPhone(value);
        }
    };

    const handleSendToken = async () => {
        if (!Validate.email(email) || email.length < 3) {
            Session.showAlert({ str: 'Please enter your valid email.', type: 'warning' });
            return;
        }
        await sendToken();
    }

    const validatePhoto = async () => {
        if (!photo) {
            Session.showAlert({ str: 'Please upload your profile picture.', type: 'error' });
            return;
        }
        let result = await isImageBlurry({dataUrl: photo, threshold: 750});
        console.log(result);
        if (result) {
            setStep(2);
            Session.showAlert({ str: 'Please use a clear profile image.', type: 'error' });
            setPhoto("");
            return;
        }else{
            setStep(1);
        }
    }
    if (registerLoading) {
        return (
            <Loading fullPage={false} message="Registering..." />
        );
    }

    return (
        <>
            <div className="container">
                {
                    step === 1 &&
                    <div className='w3-animate-right'>
                        <div className='d-flex flex-wrap align-content-center'>
                            <Link className='dark fs-3 mt-3' to="/"><i className="bi bi-arrow-left"></i></Link>
                            <h3 className='text-center fs-3 mt-3 d-block mx-auto'>Setup your account</h3>
                        </div>
                        <div>
                            <div className='border border-primary rounded-circle mx-auto mt-5 d-flex align-items-center justify-content-center'
                                style={{ width: '150px', height: '150px' }}
                                onClick={() => setStep(2)}
                            >  {photo ? <img src={photo} className="w-100 h-100 rounded-circle w3-image" alt='' style={{ width: '100%', height: '100%', aspectRatio: '1 / 1', objectFit: 'cover' }} /> : <div className='d-flex align-items-center justify-content-center fs-3 text-primary'><i className="bi bi-plus-circle"></i></div>}

                            </div>
                            <div className='text-center mt-2'>
                                <p>Profile Picture</p>
                            </div>
                            <label>Full Name</label>

                            <INPUT type="text" value={fullname} onChange={(e: React.ChangeEvent<HTMLInputElement>) => setFullname(e.target.value)} placeholder="Enter full name" />
                            <label className='mt-3'>Email</label>

                            <INPUT type="email" value={email} onChange={(e: React.ChangeEvent<HTMLInputElement>) => setEmail(e.target.value)} placeholder="Enter email address" />
                            {
                                email.includes('@') && !Validate.email(email) &&
                                <div className='text-error text-small mt-3 mb-4'><i className="bi bi-exclamation-triangle-fill"></i> Incorrect email address</div>
                            }
                            <label className="mt-3">Phone Number</label>

                            <INPUT type="number" value={phone} onChange={handleInputChange} placeholder="Enter phone number" maxLength={11} />
                            <BLOCKBUTTON onClick={handleSubmit}
                                disabled={!Validate.email(email) || !Validate.phone(phone) || !Validate.string(fullname) || fullname.length < 3}
                                className={'mt-4 ' + Validate.email(email) && Validate.phone(phone) && Validate.string(fullname) && fullname.length > 3 ? 'btn-primary' : 'btn-inactive-primary'}>Continue</BLOCKBUTTON>

                            <div className='text-center mt-3 mb-5'><span className='text-muted'>Already have an account?</span> <Link className='text-primary' to="/login">Sign In</Link></div>
                            {/* <div className="text-end" onClick={() => setStep(5)}><span>Accept terms</span></div> */}
                        </div>
                    </div>
                }
                {(step === 2) && (
                    <div className="w3-animate-left">
                        <div className="d-flex flex-wrap align-content-center">
                            <Link className="dark fs-3 mt-3" onClick={() => setStep(1)} to="#">
                                <i className="bi bi-arrow-left"></i>
                            </Link>
                            <h3 className="text-center fs-3 mt-3 d-block mx-auto">Take Selfie</h3>
                        </div>
                        <div
                            className="border border-primary rounded-circle mx-auto mt-2 d-flex align-items-center justify-content-center w3-center justify-content-center"
                            style={{ height: '50svh', width: '300px' }}
                        >
                            <video ref={videoRef} autoPlay playsInline className='rounded-circle' style={{ width: '100%', height: '100%', aspectRatio: '1 / 1', objectFit: 'cover' }}></video>
                            <canvas ref={canvasRef} style={{ display: 'none' }} />
                        </div>
                        <div className="w3-center d-flex align-items-center justify-content-center" style={{height: "calc(100svh - 70svh)"}}>
                            <p className="text-center text-wrap">Align your face to fit into the selfie area and take the photo</p>
                        </div>
                        <div className="d-flex container position-fixed bottom-0 start-50 translate-middle-x mt-3 mb-4 justify-content-center align-items-center">
                            <div
                                className="w3-col w3-center snap-control d-flex align-items-center justify-content-center bg-muted border rounded-circle mx-3"
                                id="flashlight"
                                onClick={toggleFlashlight}
                            >
                                <i className={`bi bi-lightning-charge fs-3 ${flashlightOn ? 'text-warning' : ''}`}></i>
                            </div>
                            <div
                                className="w3-col w3-center snap-ring bg-muted border rounded-circle d-flex align-items-center justify-content-center mx-3"
                                onClick={() => takePhoto()}
                            >
                                <div className="snap-control border border-dark rounded-circle"></div>
                            </div>
                            <div className="w3-col w3-center snap-control d-flex align-items-center justify-content-center bg-muted border rounded-circle mx-3"
                                onClick={switchCamera}
                            >
                                <i className="bi bi-camera fs-3"></i>
                            </div>
                        </div>
                    </div>
                )}{step === 3 && (
                    <div className="w3-animate-left">
                        <div className="d-flex flex-wrap align-content-center">
                            <Link className="dark fs-3 mt-3" onClick={() => setStep(2)} to="#">
                                <i className="bi bi-arrow-left"></i>
                            </Link>
                            <h3 className="text-center fs-3 mt-3 d-block mx-auto">Take Selfie</h3>
                        </div>
                        <div
                            className="border border-primary rounded-circle mx-auto mt-2 d-flex align-items-center justify-content-center w3-center justify-content-center"
                            style={{ height: '50svh', width: '300px' }}
                        >
                            <img src={photo} alt="" className="w3-image w3-circle" style={{ width: '100%', height: '100%', aspectRatio: '1 / 1', objectFit: 'cover' }} />
                        </div>
                        <div className='w3-center position-fixed bottom-0 start-0 w-100'>
                            <div
                                className="d-flex justify-content-between align-items-center w3-light-grey px-3 pt-3 pb-5"
                                style={{ minHeight: '15svh' }}
                            >
                                <BUTTON className='transparent ps-3 fs-3' onClick={() => {
                                    if (stream) {
                                        stream.getTracks().forEach(track => track.stop());
                                    }
                                    setStep(2);
                                }}>
                                    Retake
                                </BUTTON>

                                <BUTTON className='transparent text-primary pe-3 fs-3' onClick={async () => {
                                    if (stream) {
                                        stream.getTracks().forEach(track => track.stop());
                                    }
                                    await validatePhoto();
                                }}>
                                    Use Photo
                                </BUTTON>
                            </div>
                        </div>

                    </div>
                )}{
                    step === 4 &&
                    <div className={'w3-animate-right '}>
                        <div className='d-flex flex-wrap align-content-center'>
                            <Link className='dark me-5 fs-3 mt-3' onClick={() => setStep(1)} to="#"><i className="bi bi-arrow-left"></i></Link>
                        </div>
                        <h3>Enter Verification Code</h3>
                        <label className='mb-5'>Kindly enter the verification code sent to <div className='fs-6 mt-1 fw-bolder'>{email}</div></label>
                        <OtpInput
                            value={otp}
                            onChange={handleCode}
                            numInputs={4}
                            renderSeparator={<span className=' flex-grow-1'>&nbsp;</span>}
                            containerStyle="d-flex flex-wrap justify-content-center"
                            inputStyle="form-control flex-grow-1 fs-2 fw-bolder text-center"
                            renderInput={(props) => <input {...props} />}
                            inputType='number'
                        />


                        <BLOCKBUTTON onClick={handleOTPSubmit} className={otp.length === 4 ? 'btn-primary mt-5' : 'btn-inactive-primary mt-5'}>Verify</BLOCKBUTTON>

                        <div className='text-center mt-3'><span className='text-muted'>Didn’t receive any code?</span> <BUTTON className='transparent text-primary p-0 mt-0' onClick={handleSendToken}>Resend OTP</BUTTON></div>
                    </div>
                }
            </div>
            {
                step === 5 && (
                    <AcceptTerms email={email} onClick={() => setStep(4)} />
                )
            }
        </>
    );
}

export default Register
